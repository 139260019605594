import React from "react";
import '../stylesheets/Cotizacion.css';
import MostrarTitulo from "./HeaderPasos";
import MostrarInfoCotizacion from "./InfoCotizacion";
import { useAuth } from './AuthContext';
import useGetParams from "../utils/ServiceGetParams";
import useGetCoberturas from "../utils/ServiceGetCoberturas";
import useGetCotizacion from "../utils/ServiceGetCotizacion";


function MostrarCotizacion( {texto, paso, idSeguro, EmpCodPlanes, ProTipCodPlanes, 
                            TabCodPlanes, TabCodCelPlanes, LongSDT, AseCod, handleVolverAtras} ){

  const { infoCotizacion, loading, error} = useGetCotizacion(EmpCodPlanes, ProTipCodPlanes, 
    TabCodPlanes, TabCodCelPlanes, LongSDT);

  const coberturas = useGetCoberturas(EmpCodPlanes, ProTipCodPlanes, 
    TabCodPlanes, TabCodCelPlanes, AseCod);


  let tp = "";

  if (idSeguro == 10){
    tp = "4"
  }
  else if (idSeguro == 13){
    tp = "13"
  }
  else if (idSeguro == 15){
    tp = "6"
  }

  
  return(
    <div className="contenedor-cotizacion">
      <MostrarTitulo 
        textoTitulo={texto}
        paso={paso}
        className="titulo-cotizacion"
      />
      
      {loading ? (
        <p>Cargando información...</p>
      ) : error ? (
        <div>
          {/* <p>{error}</p>
          <button onClick={handleVolverAtras}>Volver atrás</button> */}
        </div>
      ) : (
        <MostrarInfoCotizacion 
          nombreSeguro={texto}
          idSeguro={idSeguro}
          info={infoCotizacion}
          coberturas={coberturas}
          handleVolverAtras={handleVolverAtras}
        />
      )}

    </div>
  );
}

export default MostrarCotizacion;