import React, { useEffect, useState } from "react";
import MostrarTitulo from "./HeaderPasos";
import ListaTipoPlan from "./ListaTipoPlan";
import '../stylesheets/Paso2.css';
import imageSrc from '../media/puntos 1.svg';
import TextoOpciones from "./TextoOpciones";
import { useScreen } from './ScreenContext';

function Paso2( {idTipoSeguro, nombreTipoSeguro, EmpCod, ProTipCod, 
  TabCod, TabCodCel, AseCod, handleClick, paso, 
  setBodyCotizacion, setCampoLongSDT} ){

    // Se obtiene valor actual de la variable global screen
    const {currentScreen} = useScreen();

    const [isDesktop, setIsDesktop] = useState(false);
    let tituloPlanes = '';
    let estilos;
    const id = parseInt(idTipoSeguro);

    useEffect(() => {
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 768); // Definir el ancho mínimo para considerar como escritorio
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Llamar a la función inicialmente para establecer el estado correcto
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    // Verifico el id del seguro para asignar título a la variable que luego se pasa 
    // al prop texto del componente TextoOpcones
    if ([10, 13, 15].includes(id)){
      tituloPlanes = 'Seleccioná el tipo de seguro que buscás:';
      estilos = 1;
    }
    else if ([8].includes(id)){
      tituloPlanes = 'Ámbito de cobertura';
      estilos = 2;
    }

    console.log('Título: ', tituloPlanes);

  return(
    <div className="contenedor-paso-2">
      { isDesktop &&
      <div className="contenedor-imagen-puntos">
          <img className="icono-puntos" src={imageSrc} alt="puntos" />
      </div>
      }
      <div className="contenedor-cotizador-titulo-planes">
        <MostrarTitulo
          textoTitulo={nombreTipoSeguro} 
          paso={paso}
          />
        <div className={estilos == 1 ? "contenedor-texto-planes-1" : 
                        "contenedor-texto-planes-2"}>
          <TextoOpciones 
              texto={tituloPlanes}
          />
          {paso !== 2 ? (
            window.location.reload() // Recargar la página
                        ) : (
              <ListaTipoPlan
                  handleClick={handleClick}
                  setBodyCotizacion={setBodyCotizacion}
                  setCampoLongSDT={setCampoLongSDT}
                  idTipoSeguro={idTipoSeguro}
                  EmpCod={EmpCod}
                  ProTipCod={ProTipCod}
                  TabCod={TabCod}
                  TabCodCel={TabCodCel}
                  AseCod={AseCod}
              />
          )}
        </div>
      </div>
      
    </div>
  )
}

export default Paso2;