import React, { useState, useEffect } from "react";
import '../stylesheets/SeccionMarcos.css';
import whatsapp from '../media/whatsapp1.svg';
import marcos from '../media/marcos1.svg';
import ondaMarcos from '../media/ondamarcos1.svg';

function SeccionMarcos(){

  const [isDesktop, setIsDesktop] = useState(false);

  // Definir el ancho mínimo para considerar como escritorio
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); 
    };

    // Llamar a la función inicialmente para establecer el estado correcto
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return(
    <div className="contenedor-principal-seccion-marcos">
      <div className="contenedor-imagenes-seccion-marcos">
        <img 
          className="foto-marcos-inicio"
          src={marcos}
        />
        {/* {isDesktop && // Mostrar solo si es escritorio */}
          <img 
            className="onda-marcos-inicio"
            src={ondaMarcos}
          />
        {/* } */}
      </div>
      <div className="contenedor-texto-seccion-marcos">
        <p className="contenedor-titulo-seccion-marcos">MÁS INFO</p>
        <p className="contenedor-subtitulo-seccion-marcos">
          HACIENDO 
          <span className="bold"> CLICK AQUÍ</span>
        </p>
        <div className="subrayado-seccion-marcos"></div>
        <a href="https://wa.me/5491127864306?text=¡Hola!" target="_blank">
          <button className="boton-wsp">
            <img 
              className="wsp-image-seccion-marcos"
              src={whatsapp}
              />
          </button>
        </a>
      </div>
    </div>
    
  );
}

export default SeccionMarcos;